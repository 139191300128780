/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import get from "lodash/get"
import { graphql } from "gatsby"
import { findBySlug, getBackgroundList, renderLineBreak } from "../helpers"
import AwardsModule from "../components/awards-module"
import Container from "../components/container"
import Column from "../components/column"
import Hero from "../components/hero"
import HeadquartersModule from "../components/headquarters-module"
import Page from "../components/page"
import RichText from "../components/rich-text"
import Row from "../components/row"
import Text from "../components/text"
import CTA from "../components/cta"
import cloneDeep from "lodash/cloneDeep"
import BiosModal from "../components/bios-modal"
import Quote from "../components/design-partner/quote"

import ViewMoreIcon from "../images/viewmore.png"
import { paramsCheck } from "../functions/functions"
import PageLink from "../components/page-link"
import RollOverCard from "../components/rollover-card"

const TwoColumnsModule = ({
  bladePatternData,
  columnSizes,
  titleStyle,
  showBios,
}) => {
  const [showMoreContent, setShowMoreContent] = React.useState(0)
  if (!bladePatternData.contentCards || !bladePatternData.contentCards.length)
    return null
  const leftColumn = bladePatternData.contentCards[0]

  const rightColumn = bladePatternData.contentCards.slice(
    1,
    bladePatternData.contentCards.length
  )

  // console.log("right left",leftColumn,rightColumn)
  const readMoreIdx = leftColumn.description.json.content.findIndex(
    (node, idx) => {
      const nodeContent = get(node, "content[0].value", "")
      return nodeContent.trim() === "<READ MORE>"
    }
  )
  let initialContent = cloneDeep(leftColumn.description)
  let readMoreContent = cloneDeep(leftColumn.description)
  if (readMoreIdx) {
    initialContent.json.content = initialContent.json.content.slice(
      0,
      readMoreIdx
    )
    readMoreContent.json.content = readMoreContent.json.content.slice(
      readMoreIdx + 1,
      readMoreContent.json.length
    )
  }

  return (
    <div
      sx={{
        py: theme => [
          theme.spacing.vertical.sm,
          theme.spacing.vertical.sm,
          theme.spacing.vertical.lg,
        ],
      }}
    >
      <Container>
        <Row>
          <Column size={get(columnSizes, "left", [12, 12, 8])}>
            <Text
              type="h2"
              customStyle={{
                mb: theme => theme.spacing.vertical.sm,
                ...titleStyle,
              }}
            >
              {renderLineBreak(bladePatternData.title)}
            </Text>

            {initialContent ? (
              <RichText data={initialContent} showBios={showBios} textStyle={{fontSize:17}}/>
            ) : null}
            {readMoreIdx && !showMoreContent ? (
              <div
                onClick={() => setShowMoreContent(1)}
                onKeyDown={() => setShowMoreContent(1)}
                role="button"
                tabIndex="0"
                sx={{ cursor: "pointer" }}
              >
                <Text
                  customStyle={{
                    color: theme => theme.colors.primary,
                    mt: theme => theme.space[3],
                    fontSize:17
                  }}
                >
                  Read more
                  <span
                    sx={{
                      fontSize: 14,
                      ml: 2,
                      display: "inline-block",
                      transform: "scaleX(1.8) translateY(-5px)",
                    }}
                  >
                    {"\u2304"}
                  </span>
                </Text>
              </div>
            ) : null}
            {readMoreIdx && showMoreContent ? (
              <div>
                <RichText data={readMoreContent} showBios={showBios} />
                <div
                  onClick={() => setShowMoreContent(0)}
                  onKeyDown={() => setShowMoreContent(0)}
                  role="button"
                  tabIndex="0"
                  sx={{ cursor: "pointer" }}
                >
                  <Text
                    customStyle={{
                      color: theme => theme.colors.primary,
                      mt: theme => theme.space[3],
                      fontSize:17
                    }}
                  >
                    Read less
                    <span
                      sx={{
                        fontSize: 14,
                        ml: 2,
                        display: "inline-block",
                        transform: "scaleX(1.8) translateY(7px) rotate(180deg)",
                      }}
                    >
                      {"\u2304"}
                    </span>
                  </Text>
                </div>
              </div>
            ) : null}
          </Column>
          <Column size={get(columnSizes, "right", [12, 12, 4])}>
            <Row>
              {rightColumn && rightColumn.length
                ? rightColumn
                    .filter(basicCard =>
                      get(basicCard, "desktopImage.file.url")
                    )
                    .filter(
                      (basicCard, index) => showMoreContent || index === 0
                    )
                    .map((basicCard, idx) => (
                      <Column
                        key={idx}
                        size={[12]}
                        customStyle={{
                          mt: theme => [theme.spacing.vertical.sm, 0],
                        }}
                      >
                        <img alt="" src={basicCard.desktopImage.file.url} />
                        <Text customStyle={{ mt: 2, mb: 3, fontSize:17 }}>
                          {get(
                            basicCard,
                            "description.json.content[0].content[0].value"
                          )}
                        </Text>
                      </Column>
                    ))
                : null}
            </Row>
          </Column>
        </Row>
      </Container>
    </div>
  )
}

const About = ({ data }) => {
  const [showBiosModal, setShowBiosModal] = React.useState(false)
  const [biosData, setBiosData] = React.useState(null)

  paramsCheck()

  // console.log("data==>",data);
  const setBiosModal = data => {
    data && data.bios === null
      ? setShowBiosModal(false)
      : setShowBiosModal(true)
    setBiosData(data)
  }

  const setBiosLetterModal = data => {
    data && data.bios === null
      ? setShowBiosModal(false)
      : setShowBiosModal(true)
    data.isLetter = true
    setBiosData(data)
  }

  const renderHeroBladePattern = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "company-hero-module",
    })
    const heroHeights = ["auto", 555]
    if (!bladePatternData) return
    const ctaList = bladePatternData.ctaTitle && [
      {
        text: bladePatternData.ctaTitle,
        type: "secondary",
        link: bladePatternData.ctaDestination || "/",
      },
    ]
    return (
      <Hero
        customStyle={{
          // minHeight: heroHeights,
          // height: heroHeights,
        }}
        background={{
          type: "image",
          source: {
            mobileImage: bladePatternData.mobileImage,
            desktopImage: bladePatternData.desktopImage,
          },
        }}
        verticalAlign="center"
        title={bladePatternData.title}
        // body={bladePatternData.description.description}
        ctaList={ctaList}
        type="secondary"
        // popupVideo={get(
        //   bladePatternData,
        //   "contentCards[0].ctaDestinationExternal.ctaDestinationExternal"
        // )}
      />
    )
  }

  const renderOurStory = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "company-ceo-letter-module",
    })
    if (!bladePatternData) return
    // console.log("demo",bladePatternData)
    return (
      <div
        sx={{
          backgroundColor: theme => theme.colors.backgrounds.primary,
        }}
      >
        <TwoColumnsModule
          bladePatternData={bladePatternData}
          titleStyle={{ fontSize: [30, 30, 40] }}
          columnSizes={{ left: [12, 12, 8], right: [12, 12, 4], fontSize:17 }}
          showBios={() => setBiosLetterModal(bladePatternData.contentCards[0])}
        />
      </div>
    )
  }

  const renderCompanyValuesModule = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "company-values-module",
    })

    if (!bladePatternData) return

    return (
      <div
        sx={{
          py: theme => [
            theme.spacing.vertical.sm,
            theme.spacing.vertical.sm,
            theme.spacing.vertical.md,
          ],
          backgroundColor: theme => theme.colors.backgrounds.primary,
        }}
      >
        <Container>
          <Row customStyle={{ mb: theme => theme.spacing.vertical.sm }}>
            <Column>
              <Text type="h2">{renderLineBreak(bladePatternData.title)}</Text>
            </Column>
          </Row>
          {bladePatternData.contentCards ? (
            <Row>
              {bladePatternData.contentCards.map((basicCard, idx) => (
                <Column
                  size={[12, 12, 4]}
                  customStyle={{
                    mb: theme => [
                      theme.spacing.vertical.sm,
                      theme.spacing.vertical.sm,
                      theme.spacing.vertical.md,
                    ],
                    pr: [0, 0, 30, 60],
                    ":nth-child(3), :nth-child(6)": {
                      pr: 0,
                      maxWidth: [
                        null,
                        null,
                        `calc(${100 / 3}% - 30px)`,
                        `calc(${100 / 3}% - 60px)`,
                      ],
                    },
                  }}
                  key={idx}
                >
                  <Text
                    type="h3"
                    customStyle={{
                      mb: theme => theme.spacing.vertical.sm,
                    }}
                  >
                    {renderLineBreak(basicCard.title)}
                  </Text>
                  {basicCard.description ? (
                    <RichText data={basicCard.description} />
                  ) : null}
                </Column>
              ))}
            </Row>
          ) : null}
        </Container>
      </div>
    )
  }

  const renderTodayModule = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "company-today-module",
    })
    if (!bladePatternData) return

    return (
      <TwoColumnsModule
        bladePatternData={bladePatternData}
        columnSizes={{ left: [12, 12, 8], right: [12, 12, 4] }}
      />
    )
  }

  const renderInfoModule = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "company-info-module",
    })
    if (!bladePatternData) return

    let splitStr = "The result is an"
    let descriptionShot = bladePatternData.description.description.split(
      splitStr
    )
    return (
      <div
        sx={{
          py: theme => [
            theme.spacing.vertical.sm,
            theme.spacing.vertical.sm,
            theme.spacing.vertical.md,
          ],
          // backgroundColor: theme => theme.colors.backgrounds.primary,
        }}
      >
        <Container>
          <Row>
            {descriptionShot.map((text, index) => (
              <Column size={[12, 12, 4]}>
                <Text
                  customStyle={{
                    // mb: theme => theme.spacing.vertical.sm,
                    fontSize:17
                  }}
                >
                  {renderLineBreak(index > 0 ? splitStr + text : text)}
                </Text>
              </Column>
            ))}
            <Column size={[12, 12, 1]}></Column>
            <Column size={[12, 12, 2]}>
              {bladePatternData.contentCards.map((item, index) =>
                index === 0 ? (
                  <div sx={{mb: theme => [theme.spacing.vertical.sm],mt:[3,3,0, 0]}}>
                    <CTA
                      link={item?.ctaDestination?.ctaDestination || "/"}
                      type="secondary"
                      linkType="internal"
                      customStyle={{fontSize:17}}
                    >
                      {item.ctaTitle}
                    </CTA>
                  </div>
                ) : (
                  <div sx={{}
                    // { mt: theme => [theme.spacing.vertical.sm] }
                    }>
                    <PageLink
                      customStyle={{ color: "primary" }}
                      link={item?.ctaDestination?.ctaDestination || "/"}
                      text={`${item.ctaTitle}`}
                      type={"internal"}
                    />
                  </div>
                )
              )}
            </Column>
          </Row>
        </Container>
      </div>
    )
  }

  const renderAdvantagesModule = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "company-advantages-module",
    })
    if (!bladePatternData) return
    return (
      <div
        sx={{
          py: theme => [
            theme.spacing.vertical.sm,
            theme.spacing.vertical.sm,
            theme.spacing.vertical.md,
          ],
        }}
      >
        <Container>
          <Row>
            {bladePatternData.contentCards.map(item=><Column size={[12, 12, 4]}>
              <RollOverCard
                  background={getBackgroundList(item)}
                  title={item.title}
                  description={item?.description}
                  customStyle={{maxWidth:"100%"}}
                />
            </Column>)}
            
          </Row>
        </Container>
      </div>
    )
  }

  const renderBenifitsModule = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "company-benifits-module",
    })
    if (!bladePatternData) return
    return (
      <div
        sx={{
          py: theme => [
            theme.spacing.vertical.sm,
            theme.spacing.vertical.sm,
            theme.spacing.vertical.md,
          ],
        }}
      >
        <Container>
          <Row>
            {bladePatternData.contentCards.map(item=><Column size={[12, 12, 4]}>
              <RollOverCard
                  background={getBackgroundList(item)}
                  title={item.title}
                  description={item?.description}
                />
            </Column>)}
            
          </Row>
        </Container>
      </div>
    )
  }
  const renderAwardsModule = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "reusable-awards-module",
    })
    if (!bladePatternData) return

    return (
      <div
        sx={{
          background: theme => theme.colors.backgrounds.primary,
        }}
      >
        <AwardsModule
          bladePatternData={bladePatternData}
          titleAlignament="center"
          titleTextType="h2"
        />
      </div>
    )
  }

  const renderOurTeamModule = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "company-team-module",
    })
    const bladePatternDataTeamDirector = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "company-board-of-directors",
    })

    const bladePatternDataTeamObservers = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "board-observers",
    })

    // const teamGalleryBladePatternData = findBySlug({
    //   list: data.contentfulPage.bladePatternList,
    //   slug: "company-team-gallery",
    // })

    // const renderTeamImage = idx => {
    //   const imgUrl = get(
    //     teamGalleryBladePatternData,
    //     `contentCards[${idx}].desktopImage.file.url`,
    //     "/"
    //   )
    //   return <img src={imgUrl} alt="" />
    // }
    // if (!bladePatternData) return
    // if (!bladePatternDataTeamDirector) return
    // if (!bladePatternDataTeamObservers) return
    return (
      <div
        sx={{
          py: theme => theme.spacing.vertical.sm,
        }}
      >
        {bladePatternData &&(<Container>
          <Row customStyle={{ mb: theme => theme.spacing.vertical.sm }}>
            <Text type="h2">{renderLineBreak(bladePatternData.title)}</Text>
          </Row>
          {bladePatternData.contentCards ? (
            <Row>
              {bladePatternData.contentCards.length &&
                bladePatternData.contentCards.map((basicCard, idx) => (
                  <Column
                    size={[6, 4, 2]}
                    key={idx}
                    customStyle={{
                      mb: theme => [
                        theme.spacing.vertical.sm,
                        theme.spacing.vertical.sm,
                        theme.spacing.vertical.md,
                      ],
                      cursor: "pointer",
                    }}
                  >
                    <span onClick={() => setBiosModal(basicCard, null)}>
                      <div sx={{ maxWidth: 200 }}>
                        <img
                          src={get(basicCard, "desktopImage.file.url")}
                          alt=""
                          sx={{
                            mb: theme => theme.spacing.vertical.sm,
                            width: "100%",
                            height: "auto",
                          }}
                        />
                      </div>
                      <div style={{ display: "flex" }}>
                        <Text type="h4">{basicCard.title}</Text>
                        {basicCard.bios !== null && (
                          <img
                            sx={{
                              marginTop: "-3px",
                              marginLeft: "3px",
                              height: 25,
                              filter:
                                "invert(17%) sepia(19%) saturate(59%) hue-rotate(315deg) brightness(99%) contrast(92%)",
                              position: "relative",
                            }}
                            src={ViewMoreIcon}
                            alt=""
                          ></img>
                        )}
                      </div>

                      <RichText data={basicCard.description} />
                    </span>
                  </Column>
                ))}
            </Row>
          ) : null}
        </Container>)}
        {/* {renderQuote()} */}
        {/* Section for Board of directors */}
       {bladePatternDataTeamDirector && ( <Container>
          <Row customStyle={{ my: theme => theme.spacing.vertical.sm }}>
            <Text type="h2">
              {renderLineBreak(bladePatternDataTeamDirector.title)}
            </Text>
          </Row>
          {bladePatternDataTeamDirector.contentCards ? (
            <Row>
              {bladePatternDataTeamDirector.contentCards.length &&
                bladePatternDataTeamDirector.contentCards.map(
                  (basicCard, idx) => (
                    <Column
                      size={[6, 4, 2]}
                      key={idx}
                      customStyle={{
                        mb: theme => [
                          theme.spacing.vertical.sm,
                          theme.spacing.vertical.sm,
                          theme.spacing.vertical.md,
                        ],
                        cursor: "pointer",
                      }}
                    >
                      <span onClick={() => setBiosModal(basicCard, null)}>
                        <div sx={{ maxWidth: 200 }}>
                          <img
                            src={get(basicCard, "desktopImage.file.url")}
                            alt=""
                            sx={{
                              mb: theme => theme.spacing.vertical.sm,
                              width: "100%",
                              height: "auto",
                            }}
                          />
                        </div>
                        <div style={{ display: "flex" }}>
                          <Text type="h4">{basicCard.title}</Text>
                          {basicCard.bios !== null && (
                            <img
                              sx={{
                                marginTop: "-3px",
                                marginLeft: "3px",
                                height: 25,
                                filter:
                                  "invert(17%) sepia(19%) saturate(59%) hue-rotate(315deg) brightness(99%) contrast(92%)",
                                position: "relative",
                              }}
                              src={ViewMoreIcon}
                              alt=""
                            ></img>
                          )}
                        </div>

                        <RichText data={basicCard.description} />
                      </span>
                    </Column>
                  )
                )}
            </Row>
          ) : null}
        </Container>)}
        {/* Section hided until we get content*/}

        {/* Section for Board of Observers */}
        {bladePatternDataTeamObservers && (
          <Container>
            <Row customStyle={{ my: theme => theme.spacing.vertical.sm }}>
              <Text type="h2">
                {renderLineBreak(bladePatternDataTeamObservers.title)}
              </Text>
            </Row>
            {bladePatternDataTeamObservers.contentCards ? (
              <Row>
                {bladePatternDataTeamObservers.contentCards.length &&
                  bladePatternDataTeamObservers.contentCards.map(
                    (basicCard, idx) => (
                      <Column
                        size={[6, 4, 2]}
                        key={idx}
                        customStyle={{
                          mb: theme => [
                            theme.spacing.vertical.sm,
                            theme.spacing.vertical.sm,
                            theme.spacing.vertical.md,
                          ],
                          cursor: "pointer",
                        }}
                      >
                        <span onClick={() => setBiosModal(basicCard, null)}>
                          <div sx={{ maxWidth: 200 }}>
                            <img
                              src={get(basicCard, "desktopImage.file.url")}
                              alt=""
                              sx={{
                                mb: theme => theme.spacing.vertical.sm,
                                width: "100%",
                                height: "auto",
                              }}
                            />
                          </div>
                          <div style={{ display: "flex" }}>
                            <Text type="h4">{basicCard.title}</Text>
                            {basicCard.bios !== null && (
                              <img
                                sx={{
                                  marginTop: "-3px",
                                  marginLeft: "3px",
                                  height: 25,
                                  filter:
                                    "invert(17%) sepia(19%) saturate(59%) hue-rotate(315deg) brightness(99%) contrast(92%)",
                                  position: "relative",
                                }}
                                src={ViewMoreIcon}
                                alt=""
                              ></img>
                            )}
                          </div>

                          <RichText data={basicCard.description} />
                        </span>
                      </Column>
                    )
                  )}
              </Row>
            ) : null}
          </Container>
        )}
        {/* Section hided until we get content*/}
        {/* <Container customStyle={{ pb: 5 }}>
          <Row>
            <Column
              size={[12, 12, 6]}
              customStyle={{
                mb: theme => [
                  theme.spacing.horizontal,
                  theme.spacing.horizontal,
                  0,
                ],
              }}
            >
              <Row customStyle={{ mb: theme => theme.spacing.horizontal }}>
                <Column size={[12]}>{renderTeamImage(0)}</Column>
              </Row>
              <Row>
                <Column size={[6]}>{renderTeamImage(1)}</Column>
                <Column size={[6]}>{renderTeamImage(2)}</Column>
              </Row>
            </Column>
            <Column size={[12, 12, 6]}>
              <Row customStyle={{ mb: theme => theme.spacing.horizontal }}>
                <Column size={[6]}>{renderTeamImage(3)}</Column>
                <Column size={[6]}>{renderTeamImage(4)}</Column>
              </Row>
              <Row>
                <Column size={[12]}>{renderTeamImage(5)}</Column>
              </Row>
            </Column>
          </Row>
        </Container> */}
      </div>
    )
  }

  const renderCompanyCareersModule = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "company-careers-module",
    })
    if (!bladePatternData) return

    return (
      <div
        sx={{
          py: theme => theme.spacing.vertical.lg,
          backgroundColor: theme => theme.colors.backgrounds.primary,
        }}
      >
        <Container>
          <Row>
            <Column size={[12, 4, 4]}>
              <Text type="h2">{renderLineBreak(bladePatternData.title)}</Text>
              <br/>
              {bladePatternData.description ? (
                <Text customStyle={{fontSize:17}}>{bladePatternData.description.description}</Text>
              ) : null}

              {bladePatternData.ctaTitle ? (
                <div sx={{ mt: theme => [theme.spacing.vertical.sm] }}>
                  <CTA
                    type="secondary"
                    link={get(bladePatternData, "ctaDestination", "/")}
                    linkType="internal"
                  >
                    {bladePatternData.ctaTitle}
                  </CTA>
                </div>
              ) : null}
            </Column>
            <Column size={[12, 8, 8]}>
              {bladePatternData.desktopImage ? (
                <img
                  alt=""
                  src={get(bladePatternData, "desktopImage.file.url")}
                  sx={{
                    mt: theme => [theme.spacing.vertical.md, 0],
                  }}
                />
              ) : null}
            </Column>
          </Row>
        </Container>
      </div>
    )
  }

  const renderHeadquartersModule = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "contact-us-headquarters-and-factory",
    })
    if (!bladePatternData) return

    return <HeadquartersModule bladePatternData={bladePatternData} />
  }

  const renderQuote = () => {
    if (
      !data.allContentfulBladePattern ||
      !data.allContentfulBladePattern.edges.length > 0 ||
      data.allContentfulBladePattern.edges[0].node.quote === null
    )
      return
    else {
      const bladePatternData = data.allContentfulBladePattern.edges[0].node

      return (
        <div
          sx={{
            backgroundColor: theme => theme.colors.backgrounds.primary,
            py: theme => theme.spacing.vertical.lg,
          }}
        >
          {bladePatternData.quote.map(quote => (
            <Quote name={quote.name} quote={quote} photo={quote.image} />
          ))}
        </div>
      )
    }
  }

  return (
    <React.Fragment>
      <Page data={data.contentfulPage}>
        {renderHeroBladePattern()}
        {renderInfoModule()}
        {renderOurStory()}
        {renderAdvantagesModule()}
        {/* {renderCompanyValuesModule()} */}
        {/* {renderTodayModule()} */}
        {renderOurTeamModule()}
        {renderCompanyCareersModule()}
        {renderBenifitsModule()}
        {renderAwardsModule()}
        {renderHeadquartersModule()}
      </Page>
      {showBiosModal && (
        <BiosModal onClose={() => setShowBiosModal(false)} data={biosData} />
      )}
    </React.Fragment>
  )
}

export default About

export const query = graphql`
  query aboutPageQuery {
    contentfulPage(slug: { eq: "company" }) {
      ...PageData
      ...BladePatternData
    }
    allContentfulBladePattern(
      filter: { slug: { eq: "company-quote-module" } }
    ) {
      edges {
        node {
          id
          name
          slug
          quote {
            name
            quote {
              json
            }
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`
